import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import SplitText from '../../../../../global/js/vendors/gsap-splittext/splittext';

/**
 * Storytelling A1 animation.
 * This function animates the elements in the storytelling section B1.
 *
 * @returns {void}
 */
function storytellingE1() {
    // gsap.registerPlugin(ScrollTrigger);
    //
    // const titleWrapper = $(`.js-deco-title-wrapper-1`);
    // const titles = gsap.utils.toArray(`.js-deco-title-1`);
    //

    const changeNumber = 40;
    const changeNumberOpposite = -40;

    function createTimeline(triggerClass) {
        return gsap.timeline({
            scrollTrigger: {
                trigger: triggerClass,
                pin: false,
                start: 'top 100%',
                scrub: 1,
                // snap: {
                //     snapTo: 'labels',
                //     duration: { min: 0.2, max: 3 },
                //     delay: 0.2,
                //     ease: 'power1.inOut',
                // },
            },
        });
    }

    let tl1 = createTimeline('.js-image-wrapper-1');
    let tl2 = createTimeline('.js-image-wrapper-2');
    let tl3 = createTimeline('.js-image-wrapper-3');
    let tl4 = createTimeline('.js-image-wrapper-4');
    let tl5 = createTimeline('.js-image-wrapper-5');
    let tl6 = createTimeline('.js-image-wrapper-6');

    // let tla = createTimeline('.js-anim-split-word-1');
    // let tlb = createTimeline('.js-anim-split-word-2');
    // let tlc = createTimeline('.js-anim-split-word-3');

    // let tlw1 = createTimeline('.js-anim-split-word-1');

    // Add onEnter animation to each timeline
    tl1.add(() => onEnterAnimation('.js-image-1'), 'onEnter');
    tl2.add(() => onEnterAnimation('.js-image-2'), 'onEnter');
    tl3.add(() => onEnterAnimation('.js-image-3'), 'onEnter');
    tl4.add(() => onEnterAnimation('.js-image-4'), 'onEnter');
    tl5.add(() => onEnterAnimation('.js-image-5'), 'onEnter');
    tl6.add(() => onEnterAnimation('.js-image-6'), 'onEnter');

    function onEnterAnimation(element) {
        gsap.fromTo(
            element,
            { opacity: 1, yPercent: 100 },
            { opacity: 1, yPercent: 0, duration: 1, ease: 'power1.inOut' },
        );

        // gsap.fromTo(
        //     [
        //         '#js-image-wrapper-1',
        //         '#js-image-wrapper-2',
        //         '#js-image-wrapper-3',
        //         '#js-image-wrapper-4',
        //         '#js-image-wrapper-5',
        //         '#js-image-wrapper-6',
        //     ],
        //     { opacity: 0, yPercent: 100 },
        //     {
        //         opacity: 1,
        //         yPercent: 0,
        //         duration: 1,
        //         ease: 'power1.inOut',
        //         stagger: 0.5,
        //     },
        // );
    }

    // gsap.from('.js-anim-split-word-1', {
    //     scrollTrigger: '.js-deco-title-wrapper-1', // start the animation when ".box" enters the viewport (once)
    //     yPercent: 100,
    //     delay: 0.5,
    // });
    //
    // gsap.from('.js-anim-split-word-2', {
    //     scrollTrigger: '.js-deco-title-wrapper-1', // start the animation when ".box" enters the viewport (once)
    //     yPercent: 100,
    //     delay: 0.6,
    // });
    //
    // gsap.from('.js-anim-split-word-3', {
    //     scrollTrigger: '.js-deco-title-wrapper-1', // start the animation when ".box" enters the viewport (once)
    //     yPercent: 100,
    //     delay: 0.7,
    // });

    // tlw1.from('.js-anim-split-word-1', {
    //     xPercent: 5,
    //     // rotation: 2,
    // });

    // tla.from('.js-anim-split-word-1', {
    //     x: changeNumber,
    //     // rotation: 2,
    // });
    //
    // tlb.from('.js-anim-split-word-2', {
    //     x: changeNumber,
    //     // rotation: 2,
    // });
    //
    // tlc.from('.js-anim-split-word-3', {
    //     x: changeNumber,
    //     // rotation: 2,
    // });

    tl1.from('.js-image-1', {
        x: changeNumber,
        // rotation: 2,
    });

    tl2.from('.js-image-2', {
        x: changeNumberOpposite,
        // rotation: 4,
    });

    tl3.to('.js-image-3', {
        y: 90,
        // rotation: -2,
    });

    tl4.from('.js-image-4', {
        x: changeNumber,
        // rotation: 2,
    });

    tl5.from('.js-image-5', {
        x: changeNumberOpposite,
        // rotation: 4,
    });

    tl6.to('.js-image-6', {
        y: changeNumber,
        // rotation: -2,
    });

    //
    // gsap.to('.js-story-element-3', {
    //     yPercent: -250,
    //     ease: 'none',
    //     scrollTrigger: {
    //         trigger: '.o-storytelly-1',
    //         // start: "top bottom", // the default values
    //         // end: "bottom top",
    //         scrub: true,
    //     },
    // });
    // if (titles.length) {
    //     titles.forEach((titleItem) => {
    //         // Creating the timeline
    //         const tl = gsap.timeline({
    //             scrollTrigger: {
    //                 trigger: titleItem,
    //                 start: 'top 100%',
    //             },
    //         });
    //
    //         // Selecting the elements to be animated
    //         let animatedItem = titleItem.querySelectorAll(
    //             '.js-anim-split-word',
    //         );
    //
    //         animatedItem.forEach((item) => {
    //             item.style.opacity = '1';
    //
    //             // Get the animation direction from data attribute
    //             let animationDirection = item.getAttribute('data-animation');
    //
    //             let animationType = true;
    //             let direction = 100;
    //
    //             // Check animation direction
    //             if (
    //                 animationDirection === 'top-bottom' ||
    //                 animationDirection === 'left-right'
    //             ) {
    //                 direction = -100;
    //             }
    //
    //             if (
    //                 animationDirection === 'left-right' ||
    //                 animationDirection === 'right-left'
    //             ) {
    //                 animationType = false;
    //             }
    //
    //             // Split text into separate words
    //             const elementSplit = new SplitText(item, {
    //                 type: 'words',
    //             });
    //
    //             const elementWords = elementSplit.words;
    //
    //             // Animate the split text
    //             tl.from(
    //                 elementWords,
    //                 {
    //                     ...(animationType
    //                         ? { yPercent: direction }
    //                         : { xPercent: direction }),
    //                     ease: 'power4.inOut',
    //                     duration: 1,
    //                 },
    //                 '<+=0.4',
    //             );
    //         });
    //
    //         tl.to(
    //             titleWrapper,
    //             {
    //                 duration: 1,
    //                 width: '100%',
    //                 ease: 'power4.inOut',
    //             },
    //             '<+=1',
    //         );
    //     });
    // }
    // // Get all title rows
    // const titleRows = gsap.utils.toArray(
    //     '.o-storytelling-b-1__title .a-title__row',
    // );
    //
    // // Create a timeline with scrollTrigger
    // const tl = gsap.timeline({
    //     scrollTrigger: {
    //         trigger: '.o-storytelling-b-1',
    //         start: 'top center',
    //     },
    // });
    //
    // // Animate the background set
    // tl.to('.o-storytelling-b-1__bg-set .m-background-set__bg-image-wrapper', {
    //     duration: 1.2,
    //     scale: 0.6,
    //     maxWidth: '1925px',
    //     ease: 'expo.inOut',
    // });
    //
    // // Loop through each title row
    // titleRows.forEach((titleRowItem, i) => {
    //     // Get all animated items in the title row
    //     const animatedItems = titleRowItem.querySelectorAll('.a-title__part');
    //
    //     // Loop through each animated item
    //     animatedItems.forEach((item) => {
    //         let leftValue = '0%';
    //
    //         // Check if the item's parent container has 'text-right' class
    //         if (item.parentElement.classList.contains('text-right')) {
    //             leftValue = '10%';
    //         } else if (item.parentElement.classList.contains('text-left')) {
    //             leftValue = '50%';
    //         }
    //
    //         // Animate the item
    //         tl.from(
    //             item,
    //             {
    //                 duration: 1.2,
    //                 position: 'relative',
    //                 xPercent: -50,
    //                 left: leftValue,
    //                 ease: 'expo.inOut',
    //             },
    //             '-=1.2',
    //         );
    //     });
    // });
}

// function storytellingE1() {
//     console.log('page loaded');
// }

export default storytellingE1;
