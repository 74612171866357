import './global/js/util/modernizr';
import './global/js/util/polyfills';
import './style.scss';
import './global/js/core/core';

/*
 * Animations
 */
// import scrollSkewer from './animations/scroll-skewer';
import blockquoteTextAnimation from './global/js/animations/blockquoteTextAnimation';
import cursorEffects from './global/js/animations/cursor-effects';
import parallaxScroll from './global/js/animations/parallax-light';
import progressBar from './global/js/animations/progress';
import scrollSmoother from './global/js/animations/smooth-scroll';
import letterEffects from './global/js/animations/splittext';
import titleTextAnimation from './global/js/animations/titleTextAnimation';
/*
 * Misc
 */
import dropdownHeaderInit from './global/js/misc/dropdown/dropdown-header-init';
import dropdownInit from './global/js/misc/dropdown/dropdown-init';
import externalLinks from './global/js/misc/external-links/external-links';
import filter from './global/js/misc/filter/filter';
import filterMobile from './global/js/misc/filter/filter-mobile';
import choicesJS from './global/js/misc/form/choicesJS';
import customMicromodalCall from './global/js/misc/micromodal/micromodal';
import swiperInit from './global/js/misc/swiper/swiper-init';
import tableInit from './global/js/misc/table/table-init';
/*
 * Atoms
 */
import floatingCta from './patterns/01-atoms/buttons/button-floating-cta-a/button-floating-cta-a-1/button-floating-cta-a-1';
import scrollToTop from './patterns/01-atoms/misc/scroll-to-top-a/scroll-to-top-a-1/scroll-to-top-a-1';
/*
 * Molecules
 */
import showAccordion from './patterns/02-molecules/accordions/accordion-a/accordion-a-1';
// import debugControls from './patterns/02-molecules/admin/debug-controls-a/debug-controls-a-1';
import cardImageTextD from './patterns/02-molecules/cards/card-image-text-d/card-image-text-d-1';
import multiselectDropdown from './patterns/02-molecules/dropdowns/terms-multiselect-a/terms-multiselect-a-1';
import galleryA from './patterns/02-molecules/galleries/gallery-a/gallery-a-1';
import mapBlock from './patterns/02-molecules/maps/map-a/map-a-1';
import modal from './patterns/02-molecules/modals/modal-a/modal-a-1';
import modalVideoA1 from './patterns/02-molecules/modals/modal-video-a/modal-video-a-1';
import { hamburger } from './patterns/02-molecules/nav/hamburger-a/hamburger-a-1';
// import navigateCategory from './patterns/02-molecules/nav/nav-category-a/nav-category-a-1';
import navMegaDropdownA1 from './patterns/02-molecules/nav/nav-mega-dropdown-a/nav-mega-dropdown-a-1.js';
import navMob from './patterns/02-molecules/nav/nav-mobile-a/nav-mobile-a-1';
// import searchBar from './patterns/02-molecules/search/search-bar-a/search-bar-a-1';
import { slideOut } from './patterns/02-molecules/slide-outs/slide-out-a/slide-out-a-1';
import sliderImages from './patterns/02-molecules/sliders/slider-images-a/slider-images-a-1';
import sliderTestimonials from './patterns/02-molecules/sliders/slider-testimonials-a/slider-testimonials-a-1';
import socialShare from './patterns/02-molecules/social/social-share-a/social-share-a-1';
import tabs from './patterns/02-molecules/tabs/tabs-a/tabs-a-1';
import badgesA5 from './patterns/03-organisms/repeatable-custom/badges-a/badges-a-5/badges-a-5';
import cardsB1 from './patterns/03-organisms/repeatable-custom/cards-b/cards-b-1/cards-b-1';
import cardsC1 from './patterns/03-organisms/repeatable-custom/cards-c/cards-c-1/cards-c-1';
import formOrEmbedA1 from './patterns/03-organisms/repeatable-custom/form-or-embed-a/form-or-embed-a-1/form-or-embed-a-1';
import formOrEmbedB1 from './patterns/03-organisms/repeatable-custom/form-or-embed-b/form-or-embed-b-1/form-or-embed-b-1';
import imagesSlideshowA1 from './patterns/03-organisms/repeatable-custom/images-slideshow-a/images-slideshow-a-1/images-slideshow-a-1';
import linksA1 from './patterns/03-organisms/repeatable-custom/links-a/links-a-1/links-a-1';
import storytellingA1 from './patterns/03-organisms/repeatable-custom/storytelling-a/storytelling-a-1/storytelling-a-1';
import storytellingB1 from './patterns/03-organisms/repeatable-custom/storytelling-b/storytelling-b-1/storytelling-b-1';
import storytellingB2 from './patterns/03-organisms/repeatable-custom/storytelling-b/storytelling-b-2/storytelling-b-2';
// import widgetTranslation from './patterns/02-molecules/widgets/widget-translation/widget-translation';
/*
 * Plop Entry (do not remove)
 */
/// /DO NOT ERASE OR PLOP IMPORTING WILL FAIL
/// /IMPORT FROM PLOP
import storytellingE1 from './patterns/03-organisms/repeatable-custom/storytelling-e/storytelling-e-1/storytelling-e-1';
import contentBox2 from './patterns/03-organisms/single/content-box/content-box-2/content-box-2';
/*
 * Organisms
 */
import headerInit from './patterns/03-organisms/single/header/header-init';
import hero1 from './patterns/03-organisms/single/hero/hero-1/hero-1';
import hero2 from './patterns/03-organisms/single/hero/hero-2/hero-2';
import hero3 from './patterns/03-organisms/single/hero/hero-3/hero-3';
import hero4 from './patterns/03-organisms/single/hero/hero-4/hero-4';
import hero5 from './patterns/03-organisms/single/hero/hero-5/hero-5';
import hero6 from './patterns/03-organisms/single/hero/hero-6/hero-6';
import heroAuto1 from './patterns/03-organisms/single/hero/hero-auto-1/hero-auto-1';
import heroAuto2 from './patterns/03-organisms/single/hero/hero-auto-2/hero-auto-2';
import nextPost1 from './patterns/03-organisms/single/next-post/next-post-1/next-post-1';

/*
 * Module Caller
 *
 * functions below are only run if the component exists (verified through a "data-module" in the html)
 * in the ajax container or page. Barba.js instantiates the functions below on container reload as well.
 *
 * Place in alphabetical order
 */
const modules = {
    badgesA5,
    showAccordion,
    sliderImages,
    sliderTestimonials,
    // navigateCategory,
    // searchBar,
    scrollToTop,
    floatingCta,
    socialShare,
    hamburger,
    slideOut,
    modal,
    modalVideoA1,
    // debugControls,
    tabs,
    mapBlock,
    galleryA,
    cardImageTextD,
    imagesSlideshowA1,
    cardsB1,
    cardsC1,
    multiselectDropdown,
    progressBar,
    linksA1,
    storytellingA1,
    storytellingB1,
    storytellingB2,
    formOrEmbedA1,
    formOrEmbedB1,
    nextPost1,
    scrollSmoother,
    hero1,
    hero2,
    hero3,
    hero4,
    hero5,
    hero6,
    heroAuto1,
    heroAuto2,
    /// /DO NOT ERASE OR PLOP IMPORTING WILL FAIL
    /// /MODULES FROM PLOP
    storytellingE1,
    contentBox2,
    navMegaDropdownA1,
};

/*
 * Fire JavaScript functions by if the associated "data-module" exists on the page.
 */
const calledModulesNames = [];
modules.instantiate = function (elem) {
    const $this = $(elem);
    const module = $this.attr('data-module');

    if (module === undefined) {
        throw 'Module not defined (use data-module="")';
    } else if (module in modules) {
        if ($this.attr('data-initialized') === 'true') {
            return;
        }
        new modules[module](elem);
        $this.attr('data-initialized', true);
    } else {
        throw `Module '${module}' not found`;
    }
};

jQuery(document).ready(() => {
    // widgetTranslation($);
    navMob($);
    externalLinks($);
    customMicromodalCall($);

    headerInit();
    dropdownInit();
    dropdownHeaderInit();
    swiperInit();
    tableInit();
    filterMobile();
    filter();
    choicesJS();

    /*
     * Loops through component functions that should only run if the component exists in the ajax container or page.
     * The actual check is performed through "data-module";
     */
    $('[data-module]').each(function () {
        if ($.inArray($(this).data('module'), calledModulesNames) == -1) {
            modules.instantiate(this);
            calledModulesNames.push($(this).data('module'));
        }
    });

    modal($);

    /*
     * GSAP based animations. Hide if not used.
     */
    cursorEffects($);
    letterEffects($);
    titleTextAnimation($);
    blockquoteTextAnimation($);
    // scrollSkewer($);
});

jQuery(window).scroll(() => {
    parallaxScroll($);
});
