import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

export default function () {
    gsap.registerPlugin(ScrollTrigger);

    /**
     * Register Scroll Events to show/hide mobile filter toggler and filter panel
     * @param {HTMLObjectElement} panel mobile filter panel
     * @return void
     */
    const registerScrollEvents = function (panel) {
        const targetId = panel.dataset.sync;
        const selectorBlock = `[data-filter-mobile='${targetId}']`;
        const selectorOpenBtn = `[data-target='${targetId}']`;
        const selectorPanel = `[data-sync='${targetId}']`;

        // Show Filter Button when Block is in a viewport
        ScrollTrigger.create({
            trigger: selectorBlock,
            toggleClass: {
                targets: selectorOpenBtn,
                className: 'js-filter-panel-open--active',
            },
            end: 'bottom bottom',
        });

        // Add flag for Filter Panel when Block is in a viewport
        ScrollTrigger.create({
            trigger: selectorBlock,
            toggleClass: {
                targets: selectorPanel,
                className: 'in-viewport',
            },
            end: 'bottom bottom',
        });
    };

    /**
     * Register Click Events to trigger similar buttons on main filter panel
     * @param {HTMLObjectElement} panel mobile filter panel
     * @return void
     */
    const registerClickEvents = function (panel) {
        panel.addEventListener('click', function (e) {
            const target = e.target,
                targetId = panel.dataset.sync,
                filter = document.querySelector(
                    `[data-filter-mobile='${targetId}']`,
                );

            // Click soring buttons
            const dataSort = target.closest('[data-sort]');
            if (dataSort) {
                const dataSortValue = dataSort.getAttribute('data-sort');
                filter.querySelector(`[data-sort='${dataSortValue}']`).click();
                return;
            }

            // Click filter toggle buttons
            const dataToggle = target.closest('[data-toggle]');
            if (dataToggle) {
                const dataToggleValue = dataToggle.getAttribute('data-toggle');
                filter
                    .querySelector(`[data-toggle='${dataToggleValue}']`)
                    .click();
                return;
            }

            // Click reset filter button
            const resetBtn = target.closest('.js-filter-options-reset');
            if (resetBtn) {
                filter.querySelector('.js-filter-options-reset').click();
                return;
            }
        });
    };

    /**
     * Show mobile filter panel
     * @param {Event} e
     * @return void
     */
    const showFilterPanel = function (e) {
        e.preventDefault();
        const button = e.currentTarget;
        const targetId = button.dataset.target;
        const selectorPanel = `[data-sync="${targetId}"]`;
        const panel = document.querySelector(selectorPanel);
        panel.classList.add('js-filter-panel--active');
    };

    /**
     * Hide mobile filter panel
     * @param {Event} e
     * @return void
     */
    const hideFilterPanel = function (e) {
        e.preventDefault();
        const button = e.currentTarget;
        const panel = button.closest('.js-filter-panel');
        panel.classList.remove('js-filter-panel--active');
    };

    /**
     * Set active CSS classes to mobile filter according to MixItUp state
     * @param {Event} e
     * @return void
     */
    const updateActiveFilters = function (e) {
        const state = e.detail.state,
            container = state.container,
            filterID = container
                .closest('.o-block')
                .getAttribute('data-filter-mobile'),
            filter = document.querySelector(`[data-sync='${filterID}']`);

        // data attributes selectors of mobile filter
        const selector = {
            dataSort: 'data-sort',
            dataToggle: 'data-toggle',
            dataDropdown: 'data-dropdown-type',
        };

        // get active sort and filter from the MixItUp state
        const activeSort = state.activeSort.sortString,
            activeFilter = splitSelectors(state.activeFilter.selector);

        const sortBtns = filter.querySelectorAll(`[${selector.dataSort}]`);
        const toggleBtns = filter.querySelectorAll(`[${selector.dataToggle}]`);
        const dropdowns = filter.querySelectorAll(`[${selector.dataDropdown}]`);

        // Sort buttons
        sortBtns.forEach(function (item) {
            const dataSort = item.getAttribute(selector.dataSort);
            if (activeSort === dataSort) {
                item.classList.add('mixitup-control-active');
                return;
            }
            item.classList.remove('mixitup-control-active');
        });

        // Toggle buttons
        toggleBtns.forEach(function (item) {
            const dataToggle = item.getAttribute(selector.dataToggle);
            if (activeFilter.includes(dataToggle)) {
                item.classList.add('mixitup-control-active');
                return;
            }
            item.classList.remove('mixitup-control-active');
        });

        // Dropdown togglers
        dropdowns.forEach(function (item) {
            const toggler = item.querySelector('.js-dropdown-toggler'),
                activeBtns = item.querySelectorAll('.mixitup-control-active');
            //
            if (activeBtns.length) {
                toggler.classList.add('mixitup-dropdown-active');
                return;
            }
            toggler.classList.remove('mixitup-dropdown-active');
        });
    };

    /**
     * Split coma-separated selectors into separate selectors array
     * @param {string} selectors
     * @return array
     */
    function splitSelectors(selectors) {
        let rawSelectorArray = selectors.split(', ');
        let selectorArray = [];
        let selector = '';
        let insideAttribute = false;
        let insideString = false;

        rawSelectorArray.forEach(function (item) {
            for (let i = 0; i < item.length; i++) {
                let char = item.charAt(i);

                if (char === '.' && !insideAttribute && !insideString) {
                    // If we encounter a period outside an attribute or string, add the current selector to the array and start a new one
                    selectorArray.push(selector.trim());
                    selector = '.';
                    continue;
                } else if (char === '[' && !insideString) {
                    // If we encounter an opening bracket outside a string, mark that we're inside an attribute
                    insideAttribute = true;
                    // If we encounter a period outside an attribute or string, add the current selector to the array and start a new one
                    selectorArray.push(selector.trim());
                    selector = '[';
                } else if (char === ']' && !insideString) {
                    // If we encounter a closing bracket outside a string, mark that we're no longer inside an attribute
                    insideAttribute = false;
                } else if (char === "'" || char === '"') {
                    // If we encounter a quote mark, toggle whether we're inside a string or not
                    insideString = !insideString;
                }

                selector += char;
            }

            // Add the final selector to the array
            selectorArray.push(selector.trim());
        });

        // delete duplicates and empty strings
        selectorArray = selectorArray.filter((c, index) => {
            return selectorArray.indexOf(c) === index && c.trim() !== '';
        });

        return selectorArray;
    }

    // Register Click Event to open Mobile Filter Panel
    const filterButtons = document.querySelectorAll('.js-filter-panel-open');
    if (filterButtons.length) {
        filterButtons.forEach(function (item) {
            item.addEventListener('click', showFilterPanel);
        });
    }

    // Register Click Event to close Mobile Filter Panel
    const hideButtons = document.querySelectorAll('.js-filter-panel-close');
    if (hideButtons.length) {
        hideButtons.forEach(function (item) {
            item.addEventListener('click', hideFilterPanel);
        });
    }

    // Register Event for Mobile Filter Panel
    const filterPanels = document.querySelectorAll('.js-filter-panel');
    if (filterPanels.length) {
        filterPanels.forEach(registerScrollEvents);
        filterPanels.forEach(registerClickEvents);
    }

    // Register custom Event to update Mobile Filter Panel active button state
    document.addEventListener(
        'dod.mixitup.mixInitialized',
        updateActiveFilters,
    );
    // Register custom Event to update Mobile Filter Panel active button state
    document.addEventListener('dod.mixitup.mixStart', updateActiveFilters);
}
