import { gsap } from 'gsap';

function heroAuto1() {
    const heroType = $('*[data-hero-type]').data('hero-type');

    const titles = gsap.utils.toArray(`.o-hero-${heroType} .a-title`);
    const sideTitle = $(`.o-hero-${heroType} .a-side-title-a`);
    const sideTitleTextWrapper = $(
        `.o-hero-${heroType} .a-side-title-a__text-wrapper`,
    );
    const sideTitleLine = $(
        `.o-hero-${heroType} .a-side-title-a__line-wrapper`,
    );

    const tl = gsap.timeline();

    if (sideTitle.length) {
        tl.fromTo(
            sideTitleLine,
            { scaleY: 0 },
            { duration: 1.2, scaleY: 1, ease: 'power4.inOut' },
        ).fromTo(
            sideTitleTextWrapper,
            { opacity: 0, xPercent: 100 },
            { duration: 1, opacity: 1, xPercent: 0, ease: 'power4.inOut' },
            '<-=0.05',
        );
    }

    if (titles.length) {
        titles.forEach((titleItem, index) => {
            tl.to(titleItem, {
                scrollTrigger: {
                    trigger: '.o-hero-auto-1',
                    start: 'top top',
                    scrub: 1,
                },
                xPercent: index % 2 === 0 ? 70 : -70,
            });
        });
    }
}

export default heroAuto1;
