/*

Nav Dropdowns
runs the functionality of clicking the arrow in the mobile menu dropdown
*/
function navMob($) {
    const selector = '.js-nav-drop';
    const activeClass = 'is-active';

    function clickHandler(event) {
        const button = event.currentTarget;
        if (button.classList.contains('m-nav__toggle-wrapper--active')) {
            setSubMenuInactive(button);
        } else {
            setSubMenuActive(button);
        }
    }

    function setSubMenuActive(button) {
        button.classList.add('m-nav__toggle-wrapper--active');
        button.classList.add(activeClass);
        button
            .querySelector('.js-plus-toggle')
            .classList.add('a-plus-toggle--opened');
        // animate appearance of sub-menu
        $(button).next('ul').slideDown(250);
    }

    function setSubMenuInactive(button) {
        button.classList.remove('m-nav__toggle-wrapper--active');
        button.classList.remove(activeClass);
        button
            .querySelector('.js-plus-toggle')
            .classList.remove('a-plus-toggle--opened');
        // animate appearance of sub-menu
        $(button).next('ul').slideUp(250);
    }

    const dropdowns = document.querySelectorAll(selector);
    dropdowns.forEach((dropdown) => {
        setSubMenuInactive(dropdown);
        dropdown.addEventListener('click', clickHandler);
    });

    // open parent items if exists
    const parentItems = document.querySelectorAll(
        '.m-nav--primary-mobile .m-nav__item--level-0.current-menu-ancestor, .m-nav--secondary-mobile .m-nav__item--level-0.current-menu-ancestor',
    );
    parentItems.forEach((item) => {
        const toggleBtn = item.querySelector(selector);
        setSubMenuActive(toggleBtn);
    });
}

export default navMob;
