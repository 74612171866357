import { gsap } from 'gsap';

import SplitText from '../../../../../global/js/vendors/gsap-splittext/splittext';

function hero5() {
    const heroType = $('*[data-hero-type]').data('hero-type');

    const titles = gsap.utils.toArray(`.o-hero-${heroType} .a-title`);
    const pretitle = $(`.o-hero-${heroType}__pretitle`);
    const sideTitle = $(`.o-hero-${heroType} .a-side-title-a`);
    const sideTitleTextWrapper = $(
        `.o-hero-${heroType} .a-side-title-a__text-wrapper`,
    );
    const sideTitleLine = $(
        `.o-hero-${heroType} .a-side-title-a__line-wrapper`,
    );
    const subtitleContent = $(`.o-hero-${heroType}__content-wrapper .a-text`);
    const regularButton = $(`.o-hero-${heroType}__buttons-repeater .a-btn`);
    const sideImage = $(`.o-hero-${heroType}__img-wrapper .a-img`);
    const sideImageCover = $(
        `.o-hero-${heroType}__media .o-hero-${heroType}__media-cover`,
    );

    const tl = gsap.timeline();

    if (sideTitle.length) {
        tl.fromTo(
            sideTitleLine,
            { scaleY: 0 },
            { duration: 1.2, scaleY: 1, ease: 'power4.inOut' },
        ).fromTo(
            sideTitleTextWrapper,
            { opacity: 0, xPercent: 100 },
            { duration: 1, opacity: 1, xPercent: 0, ease: 'power4.inOut' },
            '<-=0.05',
        );
    }

    if (pretitle.length) {
        tl.fromTo(
            pretitle,
            { opacity: 0, yPercent: 100 },
            { duration: 1, opacity: 1, yPercent: 0, ease: 'power4.inOut' },
            '<+=0.2',
        );
    }

    if (titles.length) {
        titles.forEach((titleItem) => {
            // Selecting the elements to be animated
            let animatedItem = titleItem.querySelectorAll(
                '.js-anim-split-word',
            );

            // Loop through each animated title inner span tags
            animatedItem.forEach((item) => {
                item.style.opacity = '1';

                // Get the animation direction from data attribute
                let animationDirection = item.getAttribute('data-animation');

                let animationType = true;
                let direction = 100;

                // Check animation direction
                if (
                    animationDirection === 'top-bottom' ||
                    animationDirection === 'left-right'
                ) {
                    direction = -100;
                }

                if (
                    animationDirection === 'left-right' ||
                    animationDirection === 'right-left'
                ) {
                    animationType = false;
                }

                // Split text into separate words
                const elementSplit = new SplitText(item, {
                    type: 'words',
                });

                const elementWords = elementSplit.words;

                // Animate the split text
                tl.from(
                    elementWords,
                    {
                        ...(animationType
                            ? { yPercent: direction }
                            : { xPercent: direction }),
                        ease: 'power4.inOut',
                        duration: 1,
                        autoAlpha: animationType ? 1 : 0,
                    },
                    '<+=0.1',
                );
            });
        });
    }

    if (subtitleContent.length) {
        tl.fromTo(
            subtitleContent,
            { opacity: 0, xPercent: -100 },
            { duration: 0.5, opacity: 1, xPercent: 0, ease: 'power4.inOut' },
            '<+=0.5',
        );
    }

    if (regularButton.length) {
        tl.fromTo(regularButton, { opacity: 0 }, { opacity: 1 }, '<');
    }

    if (sideImage.length) {
        tl.fromTo(
            sideImage,
            { opacity: 0, scale: 1.2 },
            { duration: 1.2, opacity: 1, scale: 1, ease: 'power4.inOut' },
            '<',
        ).to(
            sideImageCover,
            { duration: 1.2, yPercent: 100, ease: 'power4.inOut' },
            '<',
        );
    }
}

export default hero5;
