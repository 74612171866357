/*
 * hamburger
 *
 * runs the functionality of clicking the hamburger icon in the top nav
 */

const selector = '.js-hamburger';
const activeClass = 'is-active';
const bodyActiveClass = 'h-scroll-lock nav-active';
const headerActiveClass = 'o-header--hamburger-active';
const navMenu = '.js-slide-out';
const navMenuActiveClass = 'm-slide-out--active';
const headerSearchButton = 'o-header__search-button';
const searchBar = 'm-search-bar';
const header = '.js-header';

export function hamburger() {
    $(selector).click((e) => {
        e.preventDefault();
        $(selector).toggleClass(activeClass);

        // Ensuring body cannot be scrolled up and down when the mobile nav is shown
        $('.js-body').toggleClass(bodyActiveClass);
        $('.js-header').toggleClass(headerActiveClass);

        // Makes slide-out appear
        $(navMenu).toggleClass(navMenuActiveClass);

        $(`.${headerSearchButton}`).toggleClass(`${headerSearchButton}--anim`);

        if ($(`.${searchBar}`).hasClass(`${searchBar}--active`)) {
            $(selector).parent().siblings(headerSearchButton).trigger('click');
        }

        const headerElement = $(header);
        const isHeaderUnpinned =
            headerElement.length &&
            headerElement[0].classList.contains('l-headroom--unpinned');

        if (!isHeaderUnpinned) {
            $('.js-header-top').slideToggle(250);
        }
    });

    $(window).on('resize', () => {
        if ($(window).width() >= 1140) {
            if ($('.js-hamburger').hasClass('is-active')) {
                $('.js-hamburger').trigger('click');
            }
        }
    });
}

export function hamburgerClose() {
    $(selector).removeClass(activeClass);
    $('.js-body').removeClass(bodyActiveClass);
    $('.js-header').removeClass(headerActiveClass);
    $(navMenu).removeClass(navMenuActiveClass);
}
