/*
 * External Links
 *
 * Open all external link in new tab
 */
import MicroModal from 'micromodal';
function externalLinks() {
    $('main a:not([target="_blank"])').each(function () {
        if (!$(this).attr('href')) {
            return;
        }

        if (checkExtrenalLink($(this).attr('href'))) {
            $(this).attr('target', '_blank');
        }
    });

    $('main .o-block').on('click', 'a', function (e) {
        if (
            checkExtrenalLink($(this).attr('href')) &&
            $('#modal-external-links').length > 0 &&
            !$(this).parents('.o-footer-1').length
        ) {
            e.preventDefault();
            MicroModal.show('modal-external-links');
            var extURL = $(this).attr('href');
            $('#external_link').attr('href', extURL);
        } else if (
            checkIntrenalLink($(this).attr('href')) &&
            $('#modal-internal-links').length > 0 &&
            !$(this).parents('.o-footer-1').length
        ) {
            e.preventDefault();
            MicroModal.show('modal-internal-links');
            var intURL = $(this).attr('href');
            $('#internal_link').attr('href', intURL);
        }
    });

    $('main').on('click', 'a:not([target="_blank"])', function (e) {
        if (checkExtrenalLink($(this).attr('href'))) {
            e.preventDefault();

            window.open($(this).attr('href'), '_blank');
        }
    });

    function checkExtrenalLink(href) {
        if (!href) {
            return false;
        }
        var href_split = href.split('/');

        if (
            (href_split[0] == 'http:' || href_split[0] == 'https:') &&
            href_split[2] != window.location.host
        ) {
            return true;
        } else {
            return false;
        }
    }

    function checkIntrenalLink(href) {
        if (!href) {
            return false;
        }
        var href_split = href.split('/');

        if (
            (href_split[0] == 'http:' || href_split[0] == 'https:') &&
            href_split[2] == window.location.host
        ) {
            return true;
        } else {
            return false;
        }
    }
}

export default externalLinks;
