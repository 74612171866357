import { gsap, Power4 } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import SplitText from '../vendors/gsap-splittext/splittext';

function blockquoteTextAnimation() {
    gsap.registerPlugin(ScrollTrigger);

    // Assign variable to the element that will be animated.
    const element = $('main blockquote');

    // Checking if the element exists on the page. If so, proceed.
    if (element.length) {
        // Making an array of elements. As multiple (to-be animated) elements could exist on a page.
        const elements = gsap.utils.toArray('main blockquote');

        // Looping through the array. Firing a timeline for each
        elements.forEach((item) => {
            const tl = gsap.timeline({
                delay: 0,
                scrollTrigger: {
                    trigger: item,
                    toggleActions: 'play none none none',
                    end: 'bottom center',
                },
            });

            // Animating Blockquote Deco Line (::before pseudo-element)
            tl.to(item, {
                '--blockquote-deco-line-transform-init-val': 'scaleX(1)',
                duration: 0.5,
                ease: Power4.easeOut,
                delay: 0.9,
            });

            // Checking if the condition to block the SplitText animation is met
            if (shouldAnimate(item)) {
                // Creating a splittext object for each letter/word
                const elementSplitted = new SplitText(item, {
                    type: 'chars,words',
                });
                const elementWords = elementSplitted.chars;

                // We're finally ready to animate! Staggering each letter or word
                tl.staggerFrom(
                    elementWords,
                    0.35,
                    {
                        y: 10,
                        autoAlpha: 0,
                        rotation: 1,
                        ease: Power4.easeOut,
                    },
                    0.03,
                    '-=0.01',
                );
            }
        });
    }
}

function shouldAnimate(item) {
    // Get the parent element of the blockquote
    const parentElement = $(item).parent();

    // Check if the parent element contains the class "no-splittext-animation"
    if (parentElement.hasClass('no-splittext-animation')) {
        return false; // Return false to disable the SplitText animation
    }

    // Add your other conditions here if needed

    return true; // Return true to enable the SplitText animation
}

export default blockquoteTextAnimation;
