import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

/**
 * Storytelling B1 animation.
 * This function animates the elements in the storytelling section B1.
 *
 * @returns {void}
 */
function storytellingB1() {
    gsap.registerPlugin(ScrollTrigger);

    // Get all title rows
    const titleRows = gsap.utils.toArray(
        '.o-storytelling-b-1__title .a-title__row',
    );

    // Create a timeline with scrollTrigger
    const tl = gsap.timeline({
        scrollTrigger: {
            trigger: '.o-storytelling-b-1',
            start: 'top center',
        },
    });

    // Animate the background set
    tl.to('.o-storytelling-b-1__bg-set .m-background-set__bg-image-wrapper', {
        duration: 1.2,
        scale: 0.6,
        maxWidth: '1925px',
        ease: 'expo.inOut',
    });

    // Loop through each title row
    titleRows.forEach((titleRowItem, i) => {
        // Get all animated items in the title row
        const animatedItems = titleRowItem.querySelectorAll('.a-title__part');

        // Loop through each animated item
        animatedItems.forEach((item) => {
            let leftValue = '0%';

            // Check if the item's parent container has 'text-right' class
            if (item.parentElement.classList.contains('text-right')) {
                leftValue = '10%';
            } else if (item.parentElement.classList.contains('text-left')) {
                leftValue = '50%';
            }

            // Animate the item
            tl.from(
                item,
                {
                    duration: 1.2,
                    position: 'relative',
                    xPercent: -50,
                    left: leftValue,
                    ease: 'expo.inOut',
                },
                '-=1.2',
            );
        });
    });
}

export default storytellingB1;
