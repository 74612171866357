/**
 * Scroll text [cardImageTextD]
 * Add shades in top/middle/bottom position of scrolled element
 *
 * @returns {void}
 */
export default () => {
    // Selectors of the elements used in scrollText
    const selector = {
        card: '[data-module="cardImageTextD"]',
        contentWrapper: '.m-card-image-text-d__content-wrapper',
        scrollText: '.m-card-image-text-d__text-wrapper',
        shadeTop: '.m-card-image-text-d__scroll-shade--top',
        shadeBottom: '.m-card-image-text-d__scroll-shade--bottom',
        textCaption: '.m-card-image-text-d__text-caption',
    };

    // Tailwind classes of elements in scroll top position
    const clTopPosition = {
        contentWrapper: 'position--top',
        shadeTop: 'h-1/2 opacity-0',
        shadeBottom: 'h-1/2 opacity-100',
        textCaption: 'h-auto opacity-100 mt-6',
    };

    // Tailwind classes of elements in scroll middle position
    const clMiddlePosition = {
        contentWrapper: 'position--middle',
        shadeTop: 'h-1/4 opacity-100',
        shadeBottom: 'h-1/4 opacity-100',
        textCaption: 'h-auto opacity-100 mt-6',
    };

    // Tailwind classes of elements in scroll bottom position
    const clBottomPosition = {
        contentWrapper: 'position--bottom',
        shadeTop: 'h-1/2 opacity-100',
        shadeBottom: 'h-1/2 opacity-0',
        textCaption: 'h-auto opacity-0 mt-6',
    };

    const clNoPosition = {
        contentWrapper: 'position--static',
        shadeTop: 'h-0 opacity-0',
        shadeBottom: 'h-0 opacity-0',
        textCaption: 'h-0 opacity-0 mt-0',
    };

    // Get all scrollText elements
    const scrollTexts = $(selector.scrollText);

    // Trigger scrollText on scroll event
    $(scrollTexts).scroll((e) => {
        initializeShades($(e.target));
    });

    // Trigger scrollText on resize event
    $(window).resize(() => {
        scrollTexts.each(function () {
            // Move the scroll to the top position forcibly
            this.scrollTop = 0;
            initializeShades($(this));
        });
    });

    /**
     * Initializes element with shades according to scroll position
     * @param {jQuery} el - jQuery object representing the element to initialize
     * @returns {void}
     */
    function initializeShades(el) {
        const scrollHeight = Math.round(el.prop('scrollHeight'));
        const scrollTop = Math.round(el.scrollTop());
        const elHeight = Math.round(el.height());

        const contentWrapper = el
            .closest(selector.card)
            .find(selector.contentWrapper);
        const shadeTop = el.find(selector.shadeTop);
        const shadeBottom = el.find(selector.shadeBottom);
        const textCaption = el
            .closest(selector.card)
            .find(selector.textCaption);

        // Remove all Tailwind classes for all positions
        removeShadesClasses(contentWrapper, shadeTop, shadeBottom, textCaption);
        //
        removeScrollCursor(contentWrapper);

        if (scrollHeight <= elHeight) {
            // Remove shades if nothing to scroll
            contentWrapper.addClass(clNoPosition.contentWrapper);
            shadeTop.addClass(clNoPosition.shadeTop);
            shadeBottom.addClass(clNoPosition.shadeBottom);
            textCaption.addClass(clNoPosition.textCaption);
        } else if (scrollTop === 0) {
            // Scroll reach top position
            contentWrapper.addClass(clTopPosition.contentWrapper);
            shadeTop.addClass(clTopPosition.shadeTop);
            shadeBottom.addClass(clTopPosition.shadeBottom);
            textCaption.addClass(clTopPosition.textCaption);
            //
            addScrollCursor(contentWrapper);
        } else if (scrollHeight - scrollTop - 5 <= elHeight) {
            // Scroll reach bottom position
            contentWrapper.addClass(clBottomPosition.contentWrapper);
            shadeTop.addClass(clBottomPosition.shadeTop);
            shadeTop.addClass(clBottomPosition.shadeTop);
            shadeBottom.addClass(clBottomPosition.shadeBottom);
            textCaption.addClass(clBottomPosition.textCaption);
            //
            addScrollCursor(contentWrapper);
        } else {
            // Scroll in middle position
            contentWrapper.addClass(clMiddlePosition.contentWrapper);
            shadeTop.addClass(clMiddlePosition.shadeTop);
            shadeBottom.addClass(clMiddlePosition.shadeBottom);
            textCaption.addClass(clMiddlePosition.textCaption);
            //
            addScrollCursor(contentWrapper);
        }

        // Change position of shades according to scroll
        if (scrollHeight - scrollTop >= elHeight) {
            shadeTop.css('top', `${scrollTop}px`);
            shadeBottom.css('bottom', `-${scrollTop}px`);
        }
    }

    /**
     * Remove shade elements Tailwind classes according to their state [top,middle,bottom]
     * @param {jQuery} wrapper - contentWrapper JQ-Element
     * @param {jQuery} top - shadeTop JQ-Element
     * @param {jQuery} bottom - shadeBottom JQ-Element
     * @param {jQuery} caption - text caption JQ-Element
     */
    function removeShadesClasses(wrapper, top, bottom, caption) {
        wrapper.removeClass(
            [
                clTopPosition.contentWrapper,
                clMiddlePosition.contentWrapper,
                clBottomPosition.contentWrapper,
                clNoPosition.contentWrapper,
            ].join(' '),
        );

        top.removeClass(
            [
                clTopPosition.shadeTop,
                clMiddlePosition.shadeTop,
                clBottomPosition.shadeTop,
                clNoPosition.shadeTop,
            ].join(' '),
        );

        bottom.removeClass(
            [
                clTopPosition.shadeBottom,
                clMiddlePosition.shadeBottom,
                clBottomPosition.shadeBottom,
                clNoPosition.shadeBottom,
            ].join(' '),
        );

        caption.removeClass(
            [
                clTopPosition.textCaption,
                clMiddlePosition.textCaption,
                clBottomPosition.textCaption,
                clNoPosition.textCaption,
            ].join(' '),
        );
    }

    function removeScrollCursor(wrapper) {
        wrapper.removeAttr('data-cursor-type');
    }
    function addScrollCursor(wrapper) {
        wrapper.attr('data-cursor-type', 'scroll');
    }

    /**
     * Trigger initializeShades function for each scrollText element
     */
    const initScrollTexts = () => {
        scrollTexts.each((index, element) => {
            initializeShades($(element));
        });
    };

    /**
     * Run initScrollTexts function when the DOM is ready
     */
    $(document).ready(() => {
        initScrollTexts();
    });
};
