import gsap from 'gsap';

export default class Cursor {
    constructor(options) {
        this.options = $.extend(
            true,
            {
                container: 'body',
                speed: 0.7,
                ease: 'expo.out',
                visibleTimeout: 300,
            },
            options,
        );
        this.body = $(this.options.container);
        this.el = $('<div class="cb-cursor"></div>');
        this.text = $('<div class="cb-cursor-text"></div>');
        this.init();
    }

    init() {
        this.el.append(this.text);
        this.body.append(this.el);
        this.bind();
        this.move(-window.innerWidth, -window.innerHeight, 0);
    }

    bind() {
        const self = this;
        const cursorMovementDetection = this.cursorMovementDetection();

        this.body
            .on('mouseleave', () => {
                self.hide();
            })
            .on('mouseenter', () => {
                self.show();
            })
            .on('pointermove', e => {
                this.pos = {
                    x: this.stick
                        ? this.stick.x - (this.stick.x - e.clientX) * 0.15
                        : e.clientX,
                    y: this.stick
                        ? this.stick.y - (this.stick.y - e.clientY) * 0.15
                        : e.clientY,
                };
                this.update();
            })
            .on('mousedown', () => {
                self.setState('-active');
            })
            .on('mouseup', () => {
                self.removeState('-active');
            })
            .on('mouseenter', 'a,input,textarea,button', () => {
                self.setState('-pointer');
            })
            .on('mouseleave', 'a,input,textarea,button', () => {
                self.removeState('-pointer');
            })
            .on('mouseenter', 'iframe', () => {
                self.hide();
            })
            .on('mouseleave', 'iframe', () => {
                self.show();
            })
            .on('mouseenter', '[data-cursor]', function() {
                self.setState(this.dataset.cursor);
            })
            .on('mouseleave', '[data-cursor]', function() {
                self.removeState(this.dataset.cursor);
            })
            .on('mouseenter', '[data-cursor-text]', function() {
                self.setText(this.dataset.cursorText);
            })
            .on('mouseleave', '[data-cursor-text]', function() {
                self.removeText();
            })
            .on('mouseenter', '[data-cursor-stick]', function() {
                self.setStick(this.dataset.cursorStick);
            })
            .on('mouseleave', '[data-cursor-stick]', function() {
                self.removeStick();
            })
            .on('mouseenter', '[data-cursor-type]', function() {
                cursorMovementDetection.startMovementDetection.call(self);
                self.setText(document.querySelector('.js-cursor-' + this.dataset.cursorType).innerHTML);

            })
            .on('mouseleave', '[data-cursor-type]', function() {
                cursorMovementDetection.stopMovementDetection.call(self);
                const parent = this.parentElement.closest('[data-cursor-type]');

                if(parent && parent !== this){
                    $(parent).trigger('mouseenter');
                } else {
                    self.removeText();
                }
            })
            .on('mouseenter', '.js-hide-custom-cursor', () => {
                self.hide();
            })
            .on('mouseleave', '.js-hide-custom-cursor', () => {
                self.show();
            })
            .on('mouseenter', '[data-cursor-text] .js-hide-custom-cursor, [data-cursor-type] .js-hide-custom-cursor', function() {
                self.removeText();
            })
            .on('mouseleave', '[data-cursor-text] .js-hide-custom-cursor, [data-cursor-type] .js-hide-custom-cursor', function() {
                self.setText(this.dataset.cursorText);
            });
    }

    setState(state) {
        this.el.addClass(state);
    }

    removeState(state) {
        this.el.removeClass(state);
    }

    toggleState(state) {
        this.el.toggleClass(state);
    }

    setText(text) {
        if(this.is_touch_enabled()){
            return;
        }
        this.text.html(text);
        this.el.addClass('-text');
    }

    removeText() {
        this.el.removeClass('-text');
    }

    setStick(el) {
        const target = $(el);
        const bound = target.get(0).getBoundingClientRect();
        this.stick = {
            y: bound.top + target.height() / 2,
            x: bound.left + target.width() / 2,
        };
        this.move(this.stick.x, this.stick.y, 5);
    }

    removeStick() {
        this.stick = false;
    }

    update() {
        this.move();
        this.show();
    }

    move(x, y, duration) {
        if(this.is_touch_enabled()){
            return;
        }
        gsap.to(this.el, {
            x: x || this.pos.x,
            y: y || this.pos.y,
            force3D: true,
            overwrite: true,
            ease: this.options.ease,
            duration: this.visible ? duration || this.options.speed : 0,
        });
    }

    show() {
        if(this.is_touch_enabled()){
            return;
        }
        if (this.visible) return;
        clearInterval(this.visibleInt);
        this.el.addClass('-visible');
        this.visibleInt = setTimeout(() => (this.visible = true));
    }

    hide() {
        clearInterval(this.visibleInt);
        this.el.removeClass('-visible');
        this.visibleInt = setTimeout(
            () => (this.visible = false),
            this.options.visibleTimeout,
        );
    }

    is_touch_enabled() {
        return ( 'ontouchstart' in window ) ||
            ( navigator.maxTouchPoints > 0 ) ||
            ( navigator.msMaxTouchPoints > 0 );
    }

    // Function detects cursor movement
    cursorMovementDetection() {
        let isMoving = false;
        let timer;
        let isDetectionEnabled = false;

        const self = this;

        function cursorStop() {
            isMoving = false;
            self.removeText();
        }

        function cursorMoving() {
            if (isDetectionEnabled) {
                isMoving = true;
                self.setText($('.js-cursor-' + $(this).data('cursorType')).html());
            }
        }

        function resetTimer() {
            clearTimeout(timer);
            timer = setTimeout(cursorStop, 1500); // 1.5 seconds timer to stop when the cursor is not moving
        }

        return {
            startMovementDetection: function() {
                isDetectionEnabled = true;
                setTimeout(() => {
                    document.addEventListener('mousemove', () => {
                        if (!isMoving) cursorMoving.call(self);
                        resetTimer.call(self);
                    });
                }, 500); // Add a delay of 500 milliseconds before enabling movement detection
            },
            stopMovementDetection: function() {
                isDetectionEnabled = false;
                document.removeEventListener('mousemove', () => {
                    if (!isMoving) cursorMoving.call(self);
                    resetTimer.call(self);
                });
                clearTimeout(timer);
            }
        };
    }
}
