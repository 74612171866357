import Cursor from '../vendors/gsap-cursor-hover-effect/cursor';
import Magnetic from '../vendors/gsap-cursor-hover-effect/magnetic';

/**
 * CursorEffects
 *
 * @param {object} el - DOM element.
 * @see https://michaelgudzevskyi.com/cursor-hover-effect-gsap/
 * @see https://github.com/michaelgudzevskyi/cursor-hover-effect-gsap
 */
function cursorEffects(el) {
    // Basic cursor
    // const cursor = new Cursor();

    // Cursor options with defaults
    const cursor = new Cursor({
        container: 'body', // container to attach
        speed: 0.7, // default speed
        ease: 'expo.out', // default ease (gsap)
        visibleTimeout: 300, // disappear timeout
    });

    // Automatic handle magnetic elements through attribute
    $('[data-magnetic]').each(function () {
        new Magnetic(this, {
            y: 0.2, // horizontal delta
            x: 0.2, // vertical delta
            s: 0.2, // speed
            rs: 0.7, // release speed
        });
    });
}

export default cursorEffects;
