export default () => {
    const modules = document.querySelectorAll('[data-module="cardsC1"]');

    modules.forEach(function (el) {
        const swiper = el.querySelector(
            '.o-cards-c-1__repeater--slider',
        ).swiper;

        /**
         * Bind Navigation buttons outside Swiper-container
         **/
        const arrowLeft = el.querySelector('.js-swiper-btn-prev');
        const arrowRight = el.querySelector('.js-swiper-btn-next');

        if (arrowLeft) {
            arrowLeft.addEventListener('click', function (e) {
                swiper.slidePrev();
            });
        }

        if (arrowRight) {
            arrowRight.addEventListener('click', function (e) {
                swiper.slideNext();
            });
        }
    });
};
