import getJsonFromAttr from './get-json-from-attr.js';
import toggleClass from './toggle-class.js';
import {
    addClassVanillaJs,
    removeClassVanillaJs,
} from './vanilla-js-classes.js';

class Helpers {
    static getJsonFromAttr(jsonData) {
        return getJsonFromAttr(jsonData);
    }
    static toggleClass(element, className) {
        return toggleClass(element, className);
    }
    static addClassVanillaJs(elements, className, exclude = false) {
        return addClassVanillaJs(elements, className, (exclude = false));
    }
    static removeClassVanillaJs(elements, className, exclude = false) {
        return removeClassVanillaJs(elements, className, (exclude = false));
    }
}

export default Helpers;
