/**
 * Attaches a click event listener to a fake submit button on forms with the
 * 'formOrEmbedB1' data-module attribute. The listener triggers a click event on the
 * actual submit button when the fake button is clicked.
 *
 * If the form is successfully submitted or an error occurs, the fake submit button
 * is hidden or shown, respectively.
 */

import { ScrollTrigger } from 'gsap/ScrollTrigger';

function formOrEmbedB1() {
    // Get all forms with the 'formOrEmbedB1' data-module attribute
    const forms = document.querySelectorAll('[data-module="formOrEmbedB1"]');
    StickyColumn();

    // Loop through each form
    forms.forEach((form) => {
        // Get the fake submit button
        const fakeBtn = form.querySelector('.js-fake-submit');

        // If the fake button doesn't exist, exit the function
        if (!fakeBtn) {
            return;
        }

        // Attach a click event listener to the fake button
        fakeBtn.addEventListener('click', (event) => {
            event.preventDefault();

            // Trigger a click event on the actual submit button
            const submit = form.querySelector('input[type=submit]');

            if (!submit) {
                return;
            }

            submit.click();
        });
    });

    // Listen for the 'message' event fired by the form when it is submitted or an error occurs
    window.addEventListener('message', (event) => {
        // If the message isn't related to form submission, exit the function
        if (event.data.type !== 'hsFormCallback') {
            return;
        }

        // Get the fake submit button corresponding to the form
        let fakeSubmit = getFakeSubmit(event.data.id);

        // Hide or show the fake submit button based on the event type
        if (event.data.eventName === 'onFormSubmit' && fakeSubmit) {
            fakeSubmit.style.display = 'none';
        } else if (event.data.eventName === 'onFormError' && fakeSubmit) {
            fakeSubmit.style.display = 'inline-block';
        }
    });

    /**
     * Returns the fake submit button corresponding to a form with the specified ID.
     *
     * @param {string} formId - The ID of the form.
     * @returns {HTMLElement|null} - The fake submit button corresponding to the form.
     */
    function getFakeSubmit(formId) {
        const form = document.getElementById(`hsForm_${formId}`);
        if (!form) {
            return null;
        }

        const block = form.closest('[data-module="formOrEmbedB1"]');
        if (!block) {
            return null;
        }

        return block.querySelector('.js-fake-submit');
    }

    /**
     *  Sticky navigation for the sidebar
     */
    function StickyColumn() {
        const stickyWrapperSelector =
            document.querySelectorAll('.js_sticky_column');
        const stickyColumnSelector = document.querySelectorAll(
            '.o-form-or-embed-b-1__column--2',
        );
        const defaultStickyStart = 200;
        const stickyColumnOffsetTop = 0;

        stickyWrapperSelector.forEach((stickyWrapper, index) => {
            setTimeout(function () {
                const sideContentWrapperHeight = stickyWrapper.offsetHeight;
                const stickyColumnOffsetTop =
                    stickyColumnSelector[index].getBoundingClientRect().top +
                    window.scrollY;

                const defaultStickyEnd =
                    defaultStickyStart + sideContentWrapperHeight;

                ScrollTrigger.create({
                    trigger: stickyColumnSelector[index],
                    start: 'top ' + defaultStickyStart + 'px',
                    endTrigger: stickyColumnSelector[index],
                    end: 'bottom ' + defaultStickyEnd + 'px',
                    pin: true,
                });
            }, 1000);

            window.addEventListener('scroll', () => {
                const headerHeight =
                    document.querySelector('header').offsetHeight;
                const stickyColumnOffset = stickyColumnOffsetTop - headerHeight;

                const scrollPosition = window.scrollY || window.pageYOffset;
                if (scrollPosition >= stickyColumnOffset) {
                    stickyWrapper.style.position = 'fixed';
                    stickyWrapper.style.top = '0';
                }
            });
        });
    }
}

export default formOrEmbedB1;
