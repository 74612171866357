import { gsap, Power4, TweenMax } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import SplitText from '../vendors/gsap-splittext/splittext';

function letterEffects() {
    gsap.registerPlugin(ScrollTrigger);

    // Assign variable to the element that will be animated.
    const element = $('.js-anim-split-text');

    // Checking if the element node (a div etc) has data-tags which serve as animation parameters
    // console.log($(element).data());

    // Checking if the element exists on the page. If so, proceed.
    if (element.length) {
        // Making an array of elements. As multiple (to-be animated) elements could exists on a page.
        const elements = gsap.utils.toArray('.js-anim-split-text');

        // Looping through the array. Firing a timeline for each
        elements.forEach((item) => {
            const tl = gsap.timeline({
                delay: 0,
                onStart: () => {
                    TweenMax.set(item, {
                        visibility: 'visible',
                    });
                },
                scrollTrigger: {
                    trigger: item,
                    toggleActions: 'play none none none',
                    end: 'bottom center',
                },
            });

            tl.fromTo(item, { opacity: 0 }, { opacity: 1 });

            // Creating a splittext object for each letter/word
            const elementSplitted = new SplitText(item, {
                type: 'chars,words',
            });
            const elementWords = elementSplitted.chars;

            // We're finally ready to animate! Staggering each letter or word
            tl.staggerFrom(
                elementWords,
                0.35,
                {
                    y: 10,
                    autoAlpha: 0,
                    rotation: 1,
                    ease: Power4.easeOut,
                },
                0.03,
                '+=0.5',
            );
        });
    }
}

export default letterEffects;
