import { gsap } from 'gsap';

import SplitText from '../../../../../global/js/vendors/gsap-splittext/splittext';

// The function takes in an array and two indices index1 and index2 as parameters.
// The purpose of this function is to swap the elements at the specified indices within the array.
const swapElements = (array, index1, index2) => {
    let temp = array[index1];
    array[index1] = array[index2];
    array[index2] = temp;
};

function badgesA5() {
    const titleWrapper = $(`.o-badges-a-5__title-wrapper`);
    const titles = gsap.utils.toArray(`.o-badges-a-5 .a-title`);
    const regularButton = $(`.o-badges-a-5__buttons-repeater .a-btn`);

    const tl = gsap.timeline();

    if (titles.length) {
        titles.forEach((titleItem) => {
            // Creating the timeline
            const tl = gsap.timeline({
                scrollTrigger: {
                    trigger: titleItem,
                    start: 'top 90%',
                },
            });

            // Selecting the elements to be animated
            let animatedItem = titleItem.querySelectorAll(
                '.js-anim-split-word',
            );

            // Changing the order of the second and the third words
            let animatedItemArr = Array.from(animatedItem);
            swapElements(animatedItemArr, 1, 2);

            // Loop through each animated title inner span tags
            animatedItemArr.forEach((item) => {
                item.style.opacity = '1';

                // Get the animation direction from data attribute
                let animationDirection = item.getAttribute('data-animation');

                let animationType = true;
                let direction = 100;

                // Check animation direction
                if (
                    animationDirection === 'top-bottom' ||
                    animationDirection === 'left-right'
                ) {
                    direction = -100;
                }

                if (
                    animationDirection === 'left-right' ||
                    animationDirection === 'right-left'
                ) {
                    animationType = false;
                }

                // Split text into separate words
                const elementSplit = new SplitText(item, {
                    type: 'words',
                });

                const elementWords = elementSplit.words;

                // Animate the split text
                tl.from(
                    elementWords,
                    {
                        ...(animationType
                            ? { yPercent: direction }
                            : { xPercent: direction }),
                        ease: 'power4.inOut',
                        duration: 1,
                    },
                    '<+=0.6',
                );
            });

            tl.to(
                titleWrapper,
                {
                    duration: 1,
                    width: '100%',
                    ease: 'power4.inOut',
                },
                '<+=1',
            );
        });
    }

    if (regularButton.length) {
        tl.fromTo(regularButton, { opacity: 0 }, { opacity: 1 }, '<');
    }
}

export default badgesA5;
