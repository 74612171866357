import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import ScrollSmoother from '../vendors/gsap-scrollsmoother/gsap-scrollsmoother.min';

/**
 * ScrollSmoother function
 * @returns {undefined}
 */
function scrollSmoother() {
    gsap.registerPlugin(ScrollTrigger, ScrollSmoother);

    // Refresh smooth scroll function
    function scrollTriggerRefresh() {
        window.addEventListener(
            'scroll',
            () => {
                ScrollTrigger.refresh();
            },
            { once: true },
        );

        window.addEventListener('resize', () => {
            ScrollTrigger.refresh();
        });
    }

    if (window.innerWidth > 768) {
        // Set Smoother global
        let smoother = ScrollSmoother.create({
            wrapper: '.l-site-wrapper',
            content: '.l-body-wrapper',
            smooth: 0.3, // Set smooth to 0 if width is less than 768px
            effects: true, // looks for data-speed and data-lag attributes on elements
            smoothTouch: 0.1, // much shorter smoothing time on touch devices (default is NO smoothing on touch devices)
            // normalizeScroll: true, // prevents address bar from showing/hiding on most devices, solves various other browser inconsistencies
        });

        document.addEventListener('click', function (e) {
            const target = e.target.closest('a');

            if (!target) {
                return false;
            }

            const href = target.getAttribute('href');

            if (/^#/.test(href)) {
                e.preventDefault();

                smoother.scrollTo(href, true);
            }
        });

        setTimeout(function () {
            smoother.effects('.img__m-background-set-hero', { speed: 0.8 });
            smoother.effects('.m-background-set-hero__video-inner', {
                speed: 0.8,
            });
            smoother.effects('.img__o-hero', { speed: 0.8 });
        }, 1000);

        // Set smooth effect for footer
        gsap.set('.o-footer', { yPercent: -30 });

        const footerUncover = gsap.timeline({ paused: true });

        footerUncover.to('.o-footer', { yPercent: 0, ease: 'none' });

        ScrollTrigger.create({
            trigger: '.o-footer',
            start: 'top bottom',
            end: '+=100%',
            animation: footerUncover,
            scrub: true,
        });

        if (document.readyState === 'complete') {
            // Loading hasn't finished yet
            // document.addEventListener('DOMContentLoaded', scrollTriggerRefresh);
            document.addEventListener('DOMContentLoaded', () => {
                setTimeout(scrollTriggerRefresh, 1);
            });
        } else {
            // `DOMContentLoaded` has already fired
            // scrollTriggerRefresh();
            setTimeout(scrollTriggerRefresh, 1);
        }

        // Scroll to the anchor on load
        window.onload = (event) => {
            let urlHash = window.location.href.split('#')[1];

            let scrollElem = document.querySelector('#' + urlHash);

            console.log(scrollElem, urlHash);

            if (urlHash && scrollElem) {
                gsap.to(smoother, {
                    scrollTop: smoother.offset(scrollElem, 'top'),
                    duration: 2,
                    delay: 0.6,
                });
            }
        };
    }
}

export default scrollSmoother;
