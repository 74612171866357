/**
 * Function to debounce a given function
 * @param {Function} func - The function to debounce
 * @param {number} delay - The time to wait before invoking the function after the last invocation
 * @returns {Function} - A debounced version of the given function
 */
function debounce(func, delay) {
    let timer;
    return function () {
        clearTimeout(timer);
        timer = setTimeout(func, delay);
    };
}

/**
 * Function to calculate the height of the active dropdown
 */
function activeDropdownHeightCalc() {
    const dropdownBarBottom =
        $('.js-dropdown-active .js-dropdown-bar').offset().top +
        $('.js-dropdown-active .js-dropdown-bar').outerHeight();
    const scrollTop = $(window).scrollTop();
    let activeDropdownHeightFromHeaderTop = dropdownBarBottom - scrollTop;

    const notificatoin_bar = document.querySelector('.o-notification-bar-a-1');
    const headerElement = document.querySelector('.js-header');
    const isHeaderUnpinned =
        headerElement &&
        headerElement.classList.contains('l-headroom--unpinned');

    if (notificatoin_bar && isHeaderUnpinned) {
        const notificationBarHeight = notificatoin_bar.offsetHeight;
        activeDropdownHeightFromHeaderTop += notificationBarHeight;
    }

    $('.o-header-1__dropdown-bg').css(
        'height',
        activeDropdownHeightFromHeaderTop + 'px',
    );
}

/**
 * Main function to initialize the navigation mega dropdown A1
 */
function navMegaDropdownA1() {
    const megaDropdowns = document.querySelectorAll(
        '[data-module="navMegaDropdownA1"]',
    );

    const dropdowns = document.querySelectorAll('.js-dropdown');
    const header = document.querySelector('.o-header-1');
    let headerActiveClass = 'o-header-1--dropdown-active';

    if (megaDropdowns.length === 0) {
        return null;
    }

    // Stop if dropdowns didn't find
    if (dropdowns.length === 0) {
        return null;
    }

    header.addEventListener('dropdownHidden', (e) => {
        header.classList.remove(headerActiveClass);

        $('.o-header-1__dropdown-bg').css('height', '0');
    });

    header.addEventListener('dropdownOpened', (e) => {
        setTimeout(function () {
            header.classList.add(headerActiveClass);
            activeDropdownHeightCalc();
        }, 1);
    });

    setDropdownOffset(megaDropdowns);

    window.addEventListener('resize', function () {
        setDropdownOffset(megaDropdowns);
    });

    window.addEventListener(
        'scroll',
        debounce(function () {
            if (header.classList.contains(headerActiveClass)) {
                activeDropdownHeightCalc();

                // 40 is a vertical offset in px before element is first unpinned
                if ($(window).scrollTop() <= 40) {
                    activeDropdownHeightCalc();
                }
            }
        }, 150),
    );
}

function setDropdownOffset(megaDropdowns) {
    megaDropdowns.forEach(function (megaDropdown) {
        let parentItem = megaDropdown.closest('.m-nav__item--level-0');
        let parentContainer = megaDropdown.closest(
            '.js-header-container-inner',
        );
        let dropdownOffset = parentItem.offsetLeft - parentContainer.offsetLeft;

        megaDropdown.querySelector(
            '.js-nav-mega-dropdown-container',
        ).style.marginLeft = dropdownOffset + 'px';
    });
}

export default navMegaDropdownA1;
