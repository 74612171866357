export default () => {
    const modules = document.querySelectorAll(
        '[data-module="imagesSlideshowA1"]',
    );

    modules.forEach(function (el) {
        if (
            !el.querySelector('.o-images-slideshow-a-1__slider-container--top')
        ) {
            return;
        }

        const swiperTop = el.querySelector(
            '.o-images-slideshow-a-1__slider-container--top',
        ).swiper;
        const swiperThumbs = el.querySelector(
            '.o-images-slideshow-a-1__slider-container--thumbs',
        ).swiper;

        // bind sliders to each other
        swiperTop.controller.control = swiperThumbs;
        swiperThumbs.controller.control = swiperTop;

        /**
         * Bind Navigation buttons outside Swiper-container
         **/
        const arrowLeft = el.querySelector('.js-swiper-btn-prev');
        const arrowRight = el.querySelector('.js-swiper-btn-next');

        if (arrowLeft) {
            arrowLeft.addEventListener('click', function (e) {
                swiperTop.slidePrev();
            });
        }

        if (arrowRight) {
            arrowRight.addEventListener('click', function (e) {
                swiperTop.slideNext();
            });
        }

        /**/
        swiperThumbs.on('slideChangeTransitionStart', function (sw) {
            sw.$el[0].classList.add('js-is-animating');
        });
        swiperThumbs.on('slideChangeTransitionEnd', function (sw) {
            sw.$el[0].classList.remove('js-is-animating');
        });
    });
};
