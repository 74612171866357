import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import SplitText from '../vendors/gsap-splittext/splittext';

/**
 * Title Text Animation function
 * This function animates text on scroll
 * @returns {undefined}
 */

// Checks if any of the elements in an array contains a specific substring and returns false
function checkArrayForSubstring(arr, substring) {
    for (let i = 0; i < arr.length; i++) {
        if (arr[i].includes(substring)) {
            return false;
        }
    }
    return true;
}

function titleTextAnimation() {
    gsap.registerPlugin(ScrollTrigger, SplitText);

    // Selecting all the title elements
    // Converting the title elements to an array
    const titles = gsap.utils.toArray('.a-title');

    // Checking if the title elements exist
    if (titles.length) {
        // Looping through the array. Firing a timeline for each
        titles.forEach((titleItem) => {
            let titleItemClassList = titleItem.classList.value.split(' ');

            // Checks if title item class list contains a specific substring and returns false
            // For Hero Organism Titles returns false
            const result = checkArrayForSubstring(titleItemClassList, 'o-hero');
            // For Badges A 5 Organism Title returns false
            const ifBadgesA5 = checkArrayForSubstring(
                titleItemClassList,
                'o-badges-a-5',
            );

            if (result && ifBadgesA5) {
                // Creating the timeline
                const tl = gsap.timeline({
                    scrollTrigger: {
                        trigger: titleItem,
                        start: 'top 90%',
                    },
                });

                // Selecting the elements to be animated
                let animatedItem = titleItem.querySelectorAll(
                    '.js-anim-split-word',
                );

                // Loop through each animated title inner span tags
                animatedItem.forEach((item) => {
                    item.style.opacity = '1';

                    // Get the animation direction from data attribute
                    let animationDirection =
                        item.getAttribute('data-animation');

                    let animationType = true;
                    let direction = 100;

                    // Check animation direction
                    if (
                        animationDirection === 'top-bottom' ||
                        animationDirection === 'left-right'
                    ) {
                        direction = -100;
                    }

                    if (
                        animationDirection === 'left-right' ||
                        animationDirection === 'right-left'
                    ) {
                        animationType = false;
                    }

                    // Split text into separate words
                    const elementSplit = new SplitText(item, {
                        type: 'words',
                    });

                    const elementWords = elementSplit.words;
                    // Animate the split text
                    tl.from(
                        elementWords,
                        {
                            ...(animationType
                                ? { yPercent: direction }
                                : { xPercent: direction }),
                            ease: 'power4.inOut',
                            duration: 1,
                            autoAlpha: animationType ? 1 : 0,
                        },
                        '<+=0.1',
                    );
                });
            }
        });
    }
}

export default titleTextAnimation;
