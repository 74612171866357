import Swiper, { Autoplay, Navigation, Pagination } from 'swiper';

function linksA1() {
    Swiper.use([Autoplay, Navigation, Pagination]);

    const paginationLinks = document.querySelectorAll(
        '.o-links-a-1__pagination',
    );

    const swiper = new Swiper('.js-links-a-1', {
        loop: false,
        speed: 600,
        spaceBetween: 32,
        // watchOverflow: true,
        // autoplay: {
        //     delay: 7000,
        // },
        slidesPerView: 1,
        // navigation: {
        //     prevEl: '.js-o-slider-a-1-btn-prev',
        //     nextEl: '.js-o-slider-a-1-btn-next',
        // },
        effect: 'fade',
        fadeEffect: {
            crossFade: true,
        },
        breakpoints: {
            768: {
                spaceBetween: 64,
            },
            1024: {
                spaceBetween: 0,
            },
        },
    });

    paginationLinks.forEach((link, i) => {
        link.addEventListener('mouseover', () => {
            swiper.update();
            swiper.slideTo(i);
        });
    });
}

export default linksA1;
