import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

// import ScrollSmoother from '../../../../../global/js/vendors/gsap-scrollsmoother/gsap-scrollsmoother.min';

/**
 * Storytelling A1 animation.
 * This function animates the elements in the storytelling section B1.
 *
 * @returns {void}
 */
function storytellingA1() {
    gsap.registerPlugin(ScrollTrigger);

    // // let scrollVelocity = 0;
    // let imgGroupWrapperHeight = $(
    //     '.o-storytelling-a-1__img-group-wrapper',
    // ).innerHeight();
    // let sectionHeight = $('.o-storytelling-a-1').outerHeight();
    // console.log(imgGroupWrapperHeight - sectionHeight);

    const imgWrappers = document.querySelectorAll(
        '.o-storytelling-a-1__img-wrapper',
    );

    imgWrappers.forEach((imgWrapper, i) => {
        gsap.to(imgWrapper, {
            scrollTrigger: {
                trigger: '.o-storytelling-a-1',
                markers: true,
                start: 'top top',
                /*onUpdate: function (self) {
                    scrollVelocity = self.getVelocity();
                    // console.log(scrollVelocity);
                },*/
                pin: '.o-storytelling-a-1',
                scrub: 2,
            },
            y: `-1000`,
        });
    });

    const images = gsap.utils.toArray('.o-storytelling-a-1__img-wrapper-inner');

    images.forEach((imageItem, i) => {
        let tl = gsap.timeline({
            scrollTrigger: {
                trigger: imageItem,
                start: 'top center',
            },
        });

        tl.from(imageItem, {
            duration: 1,
            xPercent: i % 2 === 0 ? 100 : -100,
            autoAlpha: 0,
            ease: 'ease.inOut',
        });

        /*tl.to(targetElement, {
            duration: 1,
            y: `${verticalDrifting}%`,
        });*/
    });
}

export default storytellingA1;
