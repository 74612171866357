/**
 * Initializes dropdown functionality for the navigation wrapper.
 *
 * @function
 */
const dropdownHeaderInit = () => {
    const headerWrapper = document.querySelector('.js-header');
    if (!headerWrapper) return;

    const dropdowns = headerWrapper.querySelectorAll('.js-dropdown');
    if (dropdowns.length === 0) return;

    const activeClass = 'js-dropdown-active';
    let dropdownHideTimeout;

    /**
     * Toggles the visibility of a dropdown.
     *
     * @param {Element} dropdown - The dropdown element to toggle.
     * @param {boolean} [forceClose=false] - Whether to force the dropdown to close.
     */
    const toggleDropdown = (dropdown, forceClose = false) => {
        const isOpen = dropdown.classList.contains(activeClass);
        if (isOpen || forceClose) {
            hideDropdown(dropdown);
        } else {
            showDropdown(dropdown);
        }
    };

    /**
     * Shows the dropdown.
     *
     * @param {Element} dropdown - The dropdown element to show.
     */
    const showDropdown = (dropdown) => {
        const dropdownList = dropdown.querySelector(
            '.m-nav-mega-dropdown__column',
        );
        dropdown.classList.add(activeClass);
        if (dropdownList) {
            dropdownList.style.opacity = '1';
            dropdownList.setAttribute('aria-expanded', 'true');
        }
        dropdown.dispatchEvent(
            new Event('dropdownOpened', { bubbles: true, composed: true }),
        );
    };

    /**
     * Hides the dropdown.
     *
     * @param {Element} dropdown - The dropdown element to hide.
     * @param {boolean} [hideBackground=false] - Signals if the background should be hidden.
     */
    const hideDropdown = (dropdown, hideBackground = false) => {
        const dropdownList = dropdown.querySelector(
            '.m-nav-mega-dropdown__column',
        );
        dropdown.classList.remove(activeClass);
        if (dropdownList) {
            dropdownList.style.opacity = '0';
            dropdownList.setAttribute('aria-expanded', 'false');
        }
        if (hideBackground) {
            dropdown.dispatchEvent(
                new Event('dropdownHidden', { bubbles: true, composed: true }),
            );
        }
    };

    /**
     * Hides all dropdowns.
     *
     * @param {boolean} [hideBackground=true] - Signals if the backgrounds should be hidden.
     */
    const hideAllDropdowns = (hideBackground = true) =>
        dropdowns.forEach((dropdown) => hideDropdown(dropdown, hideBackground));

    // Iterates over each dropdown element within the navigation wrapper.
    dropdowns.forEach((dropdown) => {
        const toggler = dropdown.querySelector('.js-dropdown-toggler');
        if (!toggler) return;

        // Makes the toggler focusable via keyboard interaction.  Accessibility support.
        toggler.setAttribute('tabindex', '0');

        // Adds an event listener for keyboard interactions on the toggler.  Accessibility support.
        toggler.addEventListener('keydown', (e) => {
            if (e.key === 'Enter') toggleDropdown(dropdown);
        });

        // Adds focus event to show the dropdown when the toggler is focused.  Accessibility support.
        toggler.addEventListener('focus', () => {
            hideAllDropdowns();
            showDropdown(dropdown);
        });

        // Adds blur event to hide the dropdown when focus moves away from the toggler.
        toggler.addEventListener('blur', (e) => {
            if (!dropdown.contains(e.relatedTarget)) hideDropdown(dropdown);
        });

        // Adds mouseover event to show the dropdown and hide others.
        dropdown.addEventListener('mouseover', () => {
            hideAllDropdowns();
            showDropdown(dropdown);
        });

        // Adds mouseleave event to hide the dropdown when the mouse leaves the dropdown area.
        dropdown.addEventListener('mouseleave', (e) => {
            if (!dropdown.contains(e.relatedTarget)) hideAllDropdowns(false);
        });
    });

    // Adds an event listener for mouseover on the headerWrapper to manage dropdown visibility.
    headerWrapper.addEventListener('mouseover', (e) => {
        clearTimeout(dropdownHideTimeout);
        const targetDropdown = e.target.closest('.js-dropdown');
        if (targetDropdown) showDropdown(targetDropdown);
    });

    // Adds mouseleave event to the headerWrapper to set a timeout that hides all dropdowns.
    headerWrapper.addEventListener('mouseleave', () => {
        dropdownHideTimeout = setTimeout(hideAllDropdowns, 300);
    });

    // Adds a focusin event listener to the document to hide all dropdowns when focus moves outside the headerWrapper.
    document.addEventListener('focusin', (e) => {
        if (!headerWrapper.contains(e.target)) {
            hideAllDropdowns();
        }
    });
};

export default dropdownHeaderInit;
