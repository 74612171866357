import { gsap } from 'gsap';

/**
 * Calculates the padding-bottom value for the hero image.
 */
function calcHeroPaddingBottomVal() {
    const heroType = $('*[data-hero-type]').data('hero-type');
    let heroImage = $(`.o-hero-${heroType} .a-img`);
    let heroHeight = $('*[data-hero-type]').outerHeight();
    let viewportHeight = $(window).height();
    let heroImagePaddingBottom = viewportHeight - heroHeight;

    heroImage.css('padding-bottom', heroImagePaddingBottom);
}

/**
 * Animates the hero section.
 */
function heroAuto2() {
    const heroType = $('*[data-hero-type]').data('hero-type');

    const title = $(`.o-hero-${heroType} .a-title`);
    const pretitle = $(`.o-hero-${heroType}__pretitle`);
    const sideTitle = $(`.o-hero-${heroType} .a-side-title-a`);
    const sideTitleTextWrapper = $(
        `.o-hero-${heroType} .a-side-title-a__text-wrapper`,
    );
    const sideTitleLine = $(
        `.o-hero-${heroType} .a-side-title-a__line-wrapper`,
    );
    const subtitleContent = $(`.o-hero-${heroType} .a-text`);
    const regularButton = $(`.o-hero-${heroType}__svg-button-wrapper .a-btn`);
    let bgHeroSet = $(`.o-hero-${heroType} .m-background-set-hero`);
    let heroImage = $(`.o-hero-${heroType} .a-img`);

    const tl = gsap.timeline();

    tl.from(bgHeroSet, {
        duration: 1.4,
        autoAlpha: 0,
        yPercent: 100,
        ease: 'expo.inOut',
    });

    if (heroImage.length) {
        tl.fromTo(
            heroImage,
            { opacity: 0, yPercent: -80, scale: 1.2 },
            {
                duration: 1.4,
                opacity: 1,
                yPercent: 0,
                scale: 1,
                ease: 'expo.inOut',
            },
            '<',
        );
    }

    if (sideTitle.length) {
        tl.fromTo(
            sideTitleLine,
            { scaleY: 0 },
            { duration: 1.2, scaleY: 1, ease: 'power4.inOut' },
        ).fromTo(
            sideTitleTextWrapper,
            { opacity: 0, xPercent: 100 },
            { duration: 1, opacity: 1, xPercent: 0, ease: 'power4.inOut' },
            '<-=0.05',
        );
    }

    if (pretitle.length) {
        tl.fromTo(
            pretitle,
            { opacity: 0, yPercent: 100 },
            { duration: 1, opacity: 1, yPercent: 0, ease: 'power4.inOut' },
            '<',
        );
    }

    if (title.length) {
        tl.fromTo(
            title,
            { opacity: 0, scale: 0.95 },
            { duration: 1.3, opacity: 1, scale: 1, ease: 'power4.inOut' },
            '<+0.1',
        );
    }

    if (subtitleContent.length) {
        tl.fromTo(
            subtitleContent,
            { opacity: 0, xPercent: -100 },
            {
                duration: 1,
                opacity: 1,
                xPercent: 0,
                ease: 'power4.inOut',
                stagger: 0.2,
            },
            '<+0.1',
        );
    }

    if (regularButton.length) {
        tl.fromTo(
            regularButton,
            { opacity: 0 },
            { opacity: 1, ease: 'power4.inOut' },
            '<+0.4',
        );
    }

    // Check if the window width is greater than 768 pixels
    if (window.innerWidth > 768) {
        // Calculate the padding-bottom value for the hero image
        calcHeroPaddingBottomVal();

        // Attach a 'resize' event listener to the window
        $(window).on('resize', function () {
            // When the window is resized, recalculate the padding-bottom value
            calcHeroPaddingBottomVal();
        });
    }
}

export default heroAuto2;
