export default () => {
    // selectors of the elements used in scrollText
    const selector = {
        card: '[data-module="contentBox2"]',
        viewMore: '.js-view-more',
        item: '.o-content-box-2__info-item',
        itemHidden: '.o-content-box-2__info-item--hidden',
        viewMoreTitle: '.o-content-box-2__info-item--view-more .a-text',
        viewMoreIValueSVG: '.o-content-box-2__info-item--view-more svg',
    };
    const links = document.querySelectorAll(
        selector.card + ' ' + selector.viewMore,
    );

    links.forEach(function (item) {
        item.addEventListener('click', function (e) {
            e.preventDefault();

            const parent = e.target.closest(selector.card),
                hiddenValues = parent.querySelectorAll(selector.itemHidden),
                title = parent.querySelector(selector.viewMoreTitle),
                titleText = title.innerHTML,
                altText = title.getAttribute('data-alt-text'),
                valueSVG = parent.querySelector(selector.viewMoreIValueSVG);

            if (hiddenValues.length) {
                hiddenValues.forEach(function (item, index) {
                    setTimeout(function () {
                        $(item).slideToggle();
                    }, index * 100);
                });
                title.innerHTML = altText;
                title.setAttribute('data-alt-text', titleText);
                valueSVG.classList.toggle('rotate-180');
            }
        });
    });
};
