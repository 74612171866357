import Choices from 'choices.js';

export default () => {
    // Choices params

    let choicesParams = {
        searchEnabled: false,
        itemSelectText: '',
    };

    // Choices init

    document.querySelectorAll('select').forEach(function (selectBox) {
        new Choices(selectBox, choicesParams);
    });

    // Hubspot support

    window.addEventListener('hbsptLoaded', (e) => {
        const form = e.target;

        const selectBoxes = form.querySelectorAll('select');

        selectBoxes.forEach(function (selectBox) {
            new Choices(selectBox, choicesParams);
        });
    });
};
