/*
 * Micromodal
 *
 * Call Micromodal from link href
 */
function customMicromodalCall() {
    document.addEventListener('click', function (e) {
        const target = e.target.closest('a');
        if (!target) {
            return false;
        }

        if (target.hasAttribute('data-micromodal-trigger')) {
            e.preventDefault();
        }
    });

    document.addEventListener('click', function (e) {
        const target = e.target.closest('a');
        if (!target) {
            return false;
        }

        let href = target.getAttribute('href');
        if (!href || !href.startsWith('#modal-')) {
            return false;
        }

        e.preventDefault();
        if (typeof MicroModal === 'undefined') {
            return false;
        }
        if (!document.querySelector(href)) {
            console.log('Popup with id "' + href + '" doesn\'t exist');
            return false;
        }

        // eslint-disable-next-line no-undef
        MicroModal.show(href.replace('#', ''), { disableScroll: true });
    });
}
export default customMicromodalCall;
